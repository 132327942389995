<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="grey lighten-5 fill-height text-xs-center">
                <v-card width="100%" :shaped="true">
                  <!-- <v-card-title> -->
                    <div class="align-center" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; margin-bottom: 16px; width: 100%; height: 80px;">
                      <v-row align="center" style="height: 70px; margin-left: 10px;">
                        <v-col :md="1">
                          <v-btn text icon large>
                            <v-icon>mdi-arrow-left</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <span class="title" style="color: #00B082">Kotabaru</span>
                          <p class="subtitle-1" style="color: #BEC2C4">Djemari Salon and Spa</p>
                        </v-col>
                      </v-row>
                    </div>
                  <!-- </v-card-title> -->
                    <v-row
                    align="center"
                    v-for="n in 1"
                    :key="n"
                    :class="n === 1 ? 'mb-6' : ''"
                    no-gutters
                    style="margin-left: 24px">
                    <v-col :md="5" style="margin-right: 16px">
                        <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 25px; padding-bottom: 0px;">
                            <div class="align-center text-center">
                            <div class="title font-regular" style="color: #BEC2C4">Total Staff</div>
                            <span class="display-1 font-weight-bold" style="color: #00D79E">15</span>
                            </div>
                            <div style="background: #00D79E; height: 8px; margin-top: 30px; border-bottom-right-radius: 10px; width: 99%"></div>
                        </v-card>
                    </v-col>
                    <v-col style="margin-right: 16px">
                        <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                            <v-row justify="center" align="center" class="mb-6" no-gutters>
                                <v-col :md="4">
                                    <v-btn fab large color="#EBFCF7">
                                        <v-icon color="#00B082" :large="true">$vuetify.icons.man</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col :md="3" align-self="center">
                                    <span class="display-1 font-weight-bold" style="color: #00D79E">5</span><br>
                                    <span class="title font-regular" style="color: #BEC2C4">Man</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col style="margin-right: 16px">
                        <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                            <v-row justify="center" align="center" class="mb-6" no-gutters>
                                <v-col :md="4">
                                    <v-btn fab large color="#EBFCF7">
                                        <v-icon color="#00B082" :large="true">$vuetify.icons.woman</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col :md="3" align-self="center">
                                    <span class="display-1 font-weight-bold" style="color: #00D79E">5</span><br>
                                    <span class="title font-regular" style="color: #BEC2C4">Woman</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    </v-row>
                        <v-card class="pa-2" :shaped="true" >
                        <v-card-title>
                            Staff Detail
                            <v-spacer></v-spacer>
                            <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            style="width: 10px;"
                            ></v-text-field>
                            <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-cloud-download</v-icon>Download</v-btn>
                            <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-printer</v-icon>Print</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers2"
                        :items="desserts2"
                        :search="search"
                        hide-default-footer
                        @click:row="clickStaff">
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.staff="{ item }">
                            <v-avatar size="36" style="margin-right: 10px">
                            <img
                                src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png"
                                alt="John"
                            >
                            </v-avatar>
                            {{ item.staff }}
                        </template>
                        <template v-slot:item.permission="{ item }">
                            <div v-if="item.permission === 0">
                                <span style="color: #00D79E">High</span>
                            </div>
                            <div v-else-if="item.permission === 1">
                                <span style="color: #E84118">Low</span>
                            </div>
                            <div v-else-if="item.permission === 2">
                                <span style="color: #888888">No Access</span>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn rounded color="#EBFCF7" style="color: #00D79E">Detail</v-btn>
                        </template>
                        </v-data-table>

                        <v-spacer></v-spacer><br>
                        <div style="margin-top: 24px; margin-bottom: 24px">
                            <v-pagination
                            v-model="page"
                            color="#00B082"
                            :length="total_page"
                            :total-visible="7"
                            circle
                            ></v-pagination>
                        </div>
                        </v-card>
                </v-card>
            </v-container>
            </div>
        </v-card>
        <v-dialog v-model="dialog" max-width="664px">
      <v-card>
        <v-card-title>
          <span class="headline">Staff Detail</span>
        </v-card-title>
        <v-card-text>
            <div max-width="600px" style="padding-left: 10px">
              <v-row align="center">
                <v-col :md="2" justify-self="center">
                  <v-avatar size="88" style="margin-right: 10px">
                    <img src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png" alt="John" >
                  </v-avatar>
                </v-col>
                <v-col >
                  <v-row align="center" style="padding-left: 20px">
                    <span class="headline font-weight-bold">Budi Sumiyati</span>
                    <v-chip
                      class="ma-2"
                      style="background: transparent linear-gradient(125deg, #D500F9 0%, #FFA000 100%) 0% 0% no-repeat padding-box;"
                      label
                      text-color="white">
                      Owner
                    </v-chip>
                  </v-row>
                  <div style="padding-left: 10px">
                    <span class="subtitle-1">Staff</span>
                  </div>
                  <!-- <div>
                    <v-chip
                      class="ma-2"
                      color="#EBFCF7"
                      label
                      text-color="#00D79E">
                      0852-9382-0934
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      color="#EEF5FA"
                      label
                      text-color="#2980B9">
                      budiaye@gmail.com
                    </v-chip>
                  </div> -->
                </v-col>
              </v-row>
            </div>
            <hr>
            <v-row>
              <v-col :md="2">
                <span class="body-1" style="color: #BEC2C4">Gender</span>
                <p class="body-1 font-weight-bold">Male</p>
              </v-col>
              <v-col :md="5">
                <span class="body-1" style="color: #BEC2C4">Employment Start Date</span>
                <p class="body-1 font-weight-bold">Sunday, 20 October 2018</p>
              </v-col>
              <v-col :md="5">
                <span class="body-1" style="color: #BEC2C4">Social Media</span>
                <p class="body-1 font-weight-bold">budisumiyabi</p>
              </v-col>
            </v-row>
            <span class="body-1" style="color: #BEC2C4">Location</span>
            <v-card v-for="item in desserts" :key="item" class="d-flex" outlined tile style="border: 1px solid #BEF4E6; border-radius: 10px; padding-left: 10px; padding-right: 12px; margin-bottom: 12px" >
              <v-row style="width: 600px" >
                <v-col class="pa-2" style="height: 40px;">
                  <span class="subtitle-1 font-weight-black" style="margin-left: 16px">{{ item.location }}</span>
                </v-col>
                <v-col class="pa-2" style="height: 40px;">
                  <span class="subtitle-1" style="color: #BEC2C4">{{ item.city }}</span>
                </v-col>
                <v-col class="text-center pa-2" style="height: 40px; background: #EBFCF7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                  <span class="subtitle-1">Hair Cutter</span>
                </v-col>
              </v-row>
            </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListActivity',
  props: ['xaxis', 'extend'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'POS',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Online',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Marketplace',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers2: [
        {
          text: 'Staff',
          align: 'left',
          value: 'staff'
        },
        {
          text: 'Gender',
          align: 'left',
          value: 'gender'
        },
        {
          text: 'Phone',
          align: 'left',
          value: 'phone'
        },
        {
          text: 'Permission',
          align: 'left',
          value: 'permission'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'action'
        }
      ],
      desserts2: [
        {
          staff: 'Rudi Sumiyati',
          phone: '0812-9182-8231',
          loc: '3',
          permission: 0,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Man'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 1,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Woman'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 2,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Woman'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 0,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Man'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'POS', 'Online', 'Marketplace'
      ],
      dataLine: {
        columns: ['date', 'service', 'product', 'classes'],
        rows: [{ 'date': '10', 'service': 10, 'product': 5, 'classes': 2 },
          { 'date': '11', 'service': 20, 'product': 20, 'classes': 6 },
          { 'date': '12', 'service': 17, 'product': 16, 'classes': 5 },
          { 'date': '13', 'service': 28, 'product': 24, 'classes': 10 }
        ]
      },
      dataRing: {
        columns: ['pos', 'online', 'marketplace'],
        rows: [{ 'pos': 10, 'online': 5, 'marketplace': 2 },
          { 'pos': 20, 'online': 20, 'marketplace': 6 },
          { 'pos': 17, 'online': 16, 'marketplace': 5 }
        ]
      },
      colorsLine: ['#83D9FF', '#B2FF59', '#C7A8FF'],
      colorsRing: ['#00B082', '#00D79E', '#7CFDBE'],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: [{
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#83D9FF'
          }, {
            offset: 1,
            color: '#f2f9fc'
          }])
        },
        {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#B2FF59'
          }, {
            offset: 1,
            color: '#cbf797'
          }])
        },
        {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#C7A8FF'
          }, {
            offset: 1,
            color: '#e5d9fa'
          }])
        }]
      },
      search: '',
      page: 1,
      total_page: 10,
      dialog: false
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    clickStaff () {
      this.dialog = true
    }
  }
}
</script>
